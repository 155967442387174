@function get-input-select-search-props($props: ()) {
    $input-padding: map-get($props, input-padding);
    @return map-merge((
        select-options-shadow: 0 3px 10px rgba(0,0,0,0.3),
        select-option-padding: $baseline / 2 get-side($input-padding, right) $baseline / 2 get-side($input-padding, left),
        select-option-line-height: $baseline * 1.5,
    ), $props);
}
@function get-input-select-search-colors($props: ()) {
    $props: get-input-props($props, input-colors);
    @return map-merge((
        select-option-background-color: map-get($props, input-background-color),
        select-option-background-hover-color: scale_color(map-get($props, input-background-color), $lightness: -5%),
        select-option-background-selected-color: scale_color(map-get($props, input-background-color), $lightness: -15%),
    ), $props);
}
@mixin input-select-search-colors($props: ()) {
    $props: get-input-props($props, input-select-search-colors);
    .select-search-options {
        background-color: map-get($props, select-option-background-color);
    }
    .select-search-option {
        transition: background-color .2s;
        &:focus,
        &:hover {
            background-color: map-get($props, select-option-background-hover-color);
        }
        &.is-selected {
            background-color: map-get($props, select-option-background-selected-color);
        }
    }
}
@mixin input-select-search($props: ()) {
    $props: get-input-props($props, input-select-search);
    $label-selector: map-get($props, label-selector);
    $field-selector: map-get($props, field-selector);
    $select-options-shadow: map-get($props, select-options-shadow);
    $select-option-padding: map-get($props, select-option-padding);
    $select-option-line-height: map-get($props, select-option-line-height);

    position: relative;
    transition: box-shadow .2s ease;
    
    .select-search-options {
        z-index: 10;
        position: absolute;
        top: 100%;
        left: 0;
        right: 0;
        padding: get-side($select-option-padding, top) 0 get-side($select-option-padding, bottom);
        animation: input-select-search-options-in .2s ease both;
        transform-origin: center top;
    }
    .select-search-option {
        display: block;
        width: 100%;
        padding: $select-option-padding;
        text-align: left;
        outline: none;
        line-height: $select-option-line-height;
        animation: input-select-search-option-in .05s .2s ease both;
    }
    &.select-options-open,
    .select-search-options {
        box-shadow: $select-options-shadow;
    }
}

@keyframes input-select-search-options-in {
    from {
        transform: scaleY(0);
        box-shadow: none;
    }
}
@keyframes input-select-search-option-in {
    from {
        opacity: 0;
    }
}