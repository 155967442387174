@import 'admin:variables';

@import '@core/breakpoint';
@import '@core/forms';

@import 'admin:button-mixins';

@import 'mixins';

.login-cont {
    @include page-layout-login;
}

.sb-login {
    @include login-form;
    .login-button {
        @include sb-button;
        @include sb-button-icons;
    }
}

.login-brand-smallbox {
    @include login-form-colors(
        $header-background-color: $primary-color
    );
}
.login-brand-amira {
    @include login-form-colors(
        $header-background-color: $secondary-color
    );
    .login-brand-logo img {
        filter: grayscale(1) brightness(10);
    }
}
