@mixin admin-tools(
    $nav-active-color: $primary-color,
    $nav-background-color: $dark-gray,
    $nav-background-color-alt: $medium-gray,
    $accent-color: $primary-color
) {
    a {
        text-decoration: none;
    }
    .layout-admin-tools.sb-has-toolbar .site {
        margin-bottom: 0;
    }
    .sb-has-toolbar {
        .sb-admin-tools-window,
        .sb-admin-tools-navigation > *:last-child {
            @include breakout(get-toolbar-height()) {
                // padding-bottom: $value;
            }
        }
    }
    .sb-admin-tools {
        @include typography(text-medium);
        min-height: 100vh;
        display: grid;
        grid-template-columns: 240px 100%;
        justify-content: end;
        border-top: 5px solid $accent-color;
        background-color: $window-background-color;
        @include breakpoint(large up) {
            grid-template-columns: 240px 1fr;
            justify-content: start;
        }
    }
    .sb-admin-tools-sidebar {
        display: grid;
        grid-template-rows: $baseline * 4 1fr auto;
        grid-auto-rows: auto;
    }
    .sb-admin-tools-sidebar {
        grid-template-columns: 100% auto;
        background-color: $nav-background-color;
        color: $text-color-white;
        & > * {
            grid-column: 1;
        }
        a {
            color: $text-color-white;
            text-decoration: none;
            font-weight: $normal;
            &:hover,
            &:focus {
                color: $nav-active-color;
            }
        }
    }
    .sb-admin-tools-window {
        display: flex;
        flex-direction: column;
    }
    .sb-admin-tools-branding {
        display: flex;
        padding: $gutter $gutter $gutter / 2;
        margin: 0;
        //note: I don't think object-position is covered by the ie polyfill,
        // so this will be centered in ie11
        @include object-fit((object-fit: contain, object-position: left));
    }
    .sb-admin-brand-link {
        display: flex;
        flex-grow: 1;
    }
    .sb-admin-tools-mobile-menu {
        @include menu-button($white);
        @include breakpoint(large up) {
            display: none;
        }
        grid-row: 1;
        grid-column: 2;
        transition: transform .3s ease,
                    background-color .3s ease;
        &::before,
        &::after {
            transition: background-color .3s ease,
                        transform .3s ease,
                        top .3s ease,
                        bottom .3s ease;

        }
        &.open {
            @include menu-button-open;
        }
        &:focus {
            outline: none;
        }
    }
    .sb-admin-tools-header {
        display: flex;
        align-items: center;
        height: $baseline * 4;
        flex-shrink: 0;
        //space for menu button
        padding: $baseline / 2 $gutter $baseline / 2 $baseline * 5;
        @include breakpoint(large up) {
            padding: $baseline * 1.5 $gutter * 2 $baseline / 2;
        }
        span {
            flex-grow: 1;
            line-height: 1;
            @include breakpoint(smallplus down) {
                display: none;
            }
        }
        .sb-button {
            margin-top: 0;
        }
    }
    .sb-admin-tools-header-text {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        @include typography(text-large);
        & > * {
            margin-left: .5rem;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .sb-admin-tools-body {
        flex-grow: 1;
        display: flex;
        position: relative;
        @include breakpoint(breakpoint-before(large) down) {
            transition: opacity .3s ease;
            .nav-open & {
                opacity: .3;
                pointer-events: none;
            }
        }
        & > * {
            flex-grow: 1;
        }
    }
    .sb-admin-tools-navigation {
        display: flex;
        flex-direction: column;
        position: relative;
        // padding-left: 4px;
        .sub-menu {
            padding-left: calc(#{$gutter} + 1em);
            a {
                padding-left: .5em;
            }
        }
        .nav-list {
            padding-top: $baseline / 2;
            padding-bottom: $baseline;
            & + .nav-list {
                // margin-top: $baseline;
                border-top: 1px solid $white;
            }
            &:nth-child(even) {
                background-color: $nav-background-color-alt;
            }
            &:last-child {
                flex-grow: 1;
            }
        }
        .nav-list > li {
            margin: $baseline / 2 0 0;
        }
        .has-sub-menu,
        .has-sectional-menu {
            & > a {
                @include sb-icon-plus(rgba(#fff, .5));
                background-position: center right $gutter * 1.5;
                background-repeat: no-repeat;
                background-size: 10px 10px;
            }
            &.is-active > a {
                @include sb-icon-minus(rgba(#fff, .5));
            }
        }
        li.is-active {
            // box-shadow: -4px 0 #fff;
            & > a {
                font-weight: $semibold;
                color: $nav-active-color;
            }
        }
        a {
            display: block;
            padding: $baseline / 4 $gutter;
        }
    }
    @include breakpoint(breakpoint-before(large) down) {
        .sb-admin-tools-sidebar {
            z-index: 20;
            position: relative;
            transition: transform .3s ease;
            &::after {
                content: '';
                display: block;
                grid-column: 2;
                grid-row: 1;
                background-color: $nav-background-color;
            }
        }
        .nav-open {
            .sb-admin-tools-sidebar {
                transform: translateX(100%);
            }
        }
    }
    
}

@mixin admin-pages-all() {
    @include admin-page-evaluation;
    @include admin-page-email-tips;
    @include admin-page-links;
}
@mixin admin-page-evaluation() {
    .sb-admin {
        .evaluation-tabs {
            .sb-button {
                opacity: .7;
            }
            .sb-button[aria-selected="true"],
            .sb-button:hover {
                opacity: 1;
            }
            .evaluation-tabs-description {
                margin-top: $baseline / 2;
            }
            @include breakpoint(medium up) {
                display: flex;
                align-items: center;
                form {
                    flex-shrink: 0;
                }
                .evaluation-tabs-description {
                    margin: $baseline 0 0 $baseline;
                }
            }
        }
        .report-table {
            caption {
                font-size: 1.125rem;
                padding-top: 0;
                text-align: left;
            }
        }
    }
}
@mixin admin-page-email-tips() {
    .sb-admin .communication-schedule .input-interval {
        input[type="number"] {
            width: calc(2ch + 2rem);
        }
    }  
}
@mixin admin-page-links() {
    .admin-links {
        @include sitemap-basic($gutter: $baseline, $list-padding: $baseline * 2);
        margin-top: $baseline * 2;
        .nav-list > li > a {
            font-weight: $semibold;
        }
        .nav-list > li {
            display: flex;
            flex-direction: column;
        }
        .sub-menu {
            margin: 0;
            padding-left: $baseline;
            padding-top: $baseline / 2;
            background: linear-gradient(to bottom, rgba($anchor-color, .08), transparent);
            flex-grow: 1;
        }
        .sub-menu .sub-menu {
            margin: 0;
            background: none;
        }
    }
}
